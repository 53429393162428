
// DO NOT EDIT THIS FILE DIRECTLY.
// Update it by running "npm run themes:generate"

:root {
  --font-family-default: Barlow, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-fluid-x2: clamp(0.79rem, calc(0.76rem + 0.17vw), 0.89rem);
  --font-size-fluid-x1: clamp(0.89rem, calc(0.85rem + 0.19vw), 1rem);
  --font-size-fluid-0: clamp(1rem, calc(0.96rem + 0.22vw), 1.13rem);
  --font-size-fluid-1: clamp(1.13rem, calc(1.08rem + 0.24vw), 1.27rem);
  --font-size-fluid-2: clamp(1.27rem, calc(1.21rem + 0.28vw), 1.42rem);
  --font-size-fluid-3: clamp(1.42rem, calc(1.36rem + 0.31vw), 1.6rem);
  --font-size-fluid-4: clamp(1.6rem, calc(1.53rem + 0.35vw), 1.8rem);
  --font-size-fluid-5: clamp(1.8rem, calc(1.72rem + 0.39vw), 2.03rem);
  --font-size-fluid-6: clamp(2.03rem, calc(1.94rem + 0.44vw), 2.28rem);
  --font-size-fluid-7: clamp(2.13rem, calc(1.75rem + 1.98vw), 3.28rem);
  --font-size-fluid-8: clamp(2.41rem, calc(1.96rem + 2.23vw), 3.69rem);
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --rounded-sm: 2px;
  --rounded-md: 4px;
  --rounded-lg: 8px;
  --rounded-full: 9999px;
  --shadow-xs: 0 1px 3px rgba(100, 100, 100, 0.09);
  --shadow-sm: 0 1px 5px rgba(100, 100, 100, 0.05);
  --shadow-md: 0 0 0 1px var(--color-neutral-border), 0 1px 5px rgba(100, 100, 100, 0.05), 0 0 40px rgba(100, 100, 100, 0.015);
  --shadow-lg: 0 0 0 1px var(--color-neutral-border), 0 5px 17px rgba(100, 100, 100, 0.14);
  --shadow-xl: 0 4px 12px rgba(100, 100, 100, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.05);
  --shadow-xxl: 0 24px 38px 3px rgba(100, 100, 100, 0.16), 0 9px 86px 8px rgba(100, 100, 100, 0.1), 0 11px 15px -7px rgba(100, 100, 100, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.05);
  --space-0: 0px;
  --space-1: 4px;
  --space-2: 8px;
  --space-3: 12px;
  --space-4: 16px;
  --space-5: 24px;
  --space-6: 36px;
  --space-7: 48px;
  --space-8: 64px;
  --color-backdrop: var(--light, rgba(0, 0, 0, 0.25)) var(--dark, rgba(0, 0, 0, 0.5));
  --color-background: var(--light, #fff) var(--dark, hsl(60, 6.0%, 9.0%));
  --color-surface: var(--light, #fff) var(--dark, hsl(60, 6.0%, 9.0%));
  --color-primary-bg-subtle: var(--light, hsl(40, 100%, 96.5%)) var(--dark, hsl(36, 80.5%, 8.0%));
  --color-primary-bg: var(--light, hsl(45, 100%, 90.8%)) var(--dark, hsl(35, 63.4%, 11.4%));
  --color-primary-bg-hover: var(--light, hsl(44, 100%, 85.8%)) var(--dark, hsl(34, 58.8%, 14.1%));
  --color-primary-bg-active: var(--light, hsl(40, 100%, 81.5%)) var(--dark, hsl(34, 58.1%, 16.7%));
  --color-primary-line-weak: var(--light, hsl(40, 100%, 81.5%)) var(--dark, hsl(34, 58.1%, 16.7%));
  --color-primary-line: var(--light, hsl(39, 83.6%, 75.4%)) var(--dark, hsl(35, 58.3%, 20.5%));
  --color-primary-border: var(--light, hsl(37, 66.9%, 68.2%)) var(--dark, hsl(35, 59.0%, 27.4%));
  --color-primary-border-hover: var(--light, hsl(35, 59.8%, 60.0%)) var(--dark, hsl(36, 60.2%, 41.4%));
  --color-primary-focus-ring: var(--light, hsl(35, 59.8%, 60.0%)) var(--dark, hsl(36, 60.2%, 41.4%));
  --color-primary-bg-solid: var(--light, hsl(42, 100%, 62.0%)) var(--dark, hsl(42, 100%, 62.0%));
  --color-primary-bg-solid-hover: var(--light, hsl(42, 100%, 55.0%)) var(--dark, hsl(43, 100%, 64.0%));
  --color-primary-text: var(--light, hsl(25, 50.0%, 38.0%)) var(--dark, hsl(43, 100%, 65.0%));
  --color-primary-text-contrast: var(--light, hsl(25, 40.0%, 22.0%)) var(--dark, hsl(41, 100%, 85.0%));
  --color-primary-placeholder: var(--light, hsl(42, 100%, 62.0%)) var(--dark, hsl(42, 100%, 62.0%));
  --color-neutral-bg-subtle: var(--light, hsl(60, 7.7%, 97.5%)) var(--dark, hsl(60, 1.9%, 10.4%));
  --color-neutral-bg: var(--light, hsl(59, 5.8%, 94.5%)) var(--dark, hsl(56, 2.4%, 15.4%));
  --color-neutral-bg-hover: var(--light, hsl(58, 5.3%, 91.8%)) var(--dark, hsl(53, 2.7%, 18.4%));
  --color-neutral-bg-active: var(--light, hsl(57, 5.1%, 89.0%)) var(--dark, hsl(51, 2.9%, 21.2%));
  --color-neutral-line-weak: var(--light, hsl(57, 5.1%, 89.0%)) var(--dark, hsl(51, 2.9%, 21.2%));
  --color-neutral-line: var(--light, hsl(56, 5.0%, 86.0%)) var(--dark, hsl(49, 3.1%, 24.3%));
  --color-neutral-border: var(--light, hsl(54, 4.9%, 81.8%)) var(--dark, hsl(47, 3.3%, 28.7%));
  --color-neutral-border-hover: var(--light, hsl(51, 5.0%, 72.4%)) var(--dark, hsl(43, 3.7%, 37.1%));
  --color-neutral-focus-ring: var(--light, hsl(51, 5.0%, 72.4%)) var(--dark, hsl(43, 3.7%, 37.1%));
  --color-neutral-bg-solid: var(--light, hsl(60, 3.0%, 53.9%)) var(--dark, hsl(50, 4.0%, 41.8%));
  --color-neutral-bg-solid-hover: var(--light, hsl(60, 2.6%, 49.0%)) var(--dark, hsl(48, 3.5%, 49.5%));
  --color-neutral-text: var(--light, hsl(50, 2.5%, 38.0%)) var(--dark, hsl(50, 5.0%, 68.3%));
  --color-neutral-text-contrast: var(--light, hsl(50, 8.0%, 12.0%)) var(--dark, hsl(56, 7.0%, 93.0%));
  --color-neutral-placeholder: var(--light, hsl(60, 3.0%, 53.9%)) var(--dark, hsl(50, 4.0%, 41.8%))
};

@import './app';
